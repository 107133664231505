// JavaScript Document
$(function () {
  $("#nav-open").on("click", function () {
    if ($(this).hasClass("active")) {
      $(this).removeClass("active");
    } else {
      $(this).addClass("active");
    }
  });
  $("#nav-close").on("click", function () {
    $("#nav-open").removeClass("active");
  });

  var nav = $("#pc_nav");
  var navflg = false;

  $("li", nav).hover(
    function () {
      $("li", nav)
        .not($(this)) // クリックしてないtrigger
        .find(".pc_dropdown")
        .hide();
      $(".pc_dropdown", this).stop().slideDown();
      $(this).children(".child").addClass("active");
      $("li", nav).not($(this)).children(".child").removeClass("active");
    },
    function () {
      $(this).children(".child").removeClass("active");
      $(".pc_dropdown", this).stop().slideUp();
      $("li", nav).not($(this)).children(".child").removeClass("active");
    }
  );

  $(window).on("scroll", function () {
    var headerH = $(".header").outerHeight(true);
    var scroll = $(window).scrollTop();
    if (scroll >= headerH) {
      //headerの高さ以上になったら
      $(".header").addClass("fixed"); //fixedというクラス名を付与
    } else {
      //それ以外は
      $(".header").removeClass("fixed"); //fixedというクラス名を除去
    }

    if (scroll >= 200) {
      //上から200pxスクロールしたら
      $("#page-top").removeClass("DownMove");
      $("#page-top").addClass("UpMove");
    } else {
      if ($("#page-top").hasClass("UpMove")) {
        $("#page-top").removeClass("UpMove");
        $("#page-top").addClass("DownMove");
      }
    }

    $(".js-anime").on("inview", function (event, isInView) {
      if (isInView) {
        $(this).stop().addClass("is-show");
      }
    });
    $(".js-Scroll").on("inview", function (event, isInView) {
      if (isInView) {
        $(this).stop().addClass("is-show");
      }
    });

    //up遅延
    var time = 0.2; //遅延時間を増やす秒数の値
    var value = time;

    $(".js-Scroll").each(function () {
      var parent = this; //親要素を取得
      var elemPos = $(this).offset().top; //要素の位置まで来たら
      //var scroll = $(window).scrollTop();//スクロール値を取得
      var windowHeight = $(window).height(); //画面の高さを取得
      var childs = $(this).children(); //子要素を取得

      if (scroll >= elemPos - windowHeight && !$(parent).hasClass("play")) {
        //指定領域内にスクロールが入ったらまた親要素にクラスplayがなければ
        $(childs).each(function () {
          if (!$(this).hasClass("fadeUp")) {
            //アニメーションのクラス名が指定されているかどうかをチェック

            $(parent).addClass("play"); //親要素にクラス名playを追加
            $(this).css("animation-delay", value + "s"); //アニメーション遅延のCSS animation-delayを追加し
            $(this).addClass("fadeUp"); //アニメーションのクラス名を追加
            value = value + time; //delay時間を増加させる

            //全ての処理を終わったらplayを外す
            var index = $(childs).index(this);
            if (childs.length - 1 == index) {
              $(parent).removeClass("play");
            }
          }
        });
      }
    });
  });
});

$(function () {
  $(".accordion-content .ac-header").click(function () {
    $(this).next(".ac-inner").slideToggle();
    $(this).toggleClass("open");
  });
});

//tabの処理
// 要素の取得
const tabItem = document.querySelectorAll(".tab-item");
const tabContent = document.querySelectorAll(".tab-content");

// tabItemに対してクリックイベントを追加
// クリックした時にtabToggle関数を発火
for (let i = 0; i < tabItem.length; i++) {
  tabItem[i].addEventListener("click", tabToggle);
}

function tabToggle() {
  // tabItemとtabContentの.activeを削除する
  for (let i = 0; i < tabItem.length; i++) {
    tabItem[i].classList.remove("active");
  }
  for (let i = 0; i < tabContent.length; i++) {
    tabContent[i].classList.remove("active");
  }

  // クリックしたtabItemに.activeを追加
  this.classList.add("active");

  // tabItemを配列にする、下記のようになる
  // [<li class="tab-item active">About</li>, <li class="tab-item">Works</li>, <li class="tab-item">Contact</li>]
  const aryTabs = Array.prototype.slice.call(tabItem);

  // 配列に格納したキーワードと最初一致したインデックスを格納
  // <li class="tab-item active">About</li>の場合は「0」が返ってくる
  const index = aryTabs.indexOf(this);

  // インデックスに対応したtabContentに.activeを追加
  tabContent[index].classList.add("active");
}
